$(document).ready(function() {
   
    $(function(){
		$('#menu').slicknav({
			prependTo:'.logo-top',
			label: '',
		});
	});

    $('a[href=#top]').click(function(){
        $('html, body').animate({scrollTop:0}, 'slow');
        return false;
    });

    $('a[href=#bottom]').click(function(){
        $('html, body').animate({scrollTop:$(document).height()}, 'slow');
        return false;
    });

    // Disable link click not scroll top
    $("a[href='#']").click(function() {
        return false
    });

    $('.popup').fancybox();
	$('a.gallery').fancybox();
	$('a.galleria').fancybox();
	$("a.popupper").fancybox({
		'titleShow'			: false,
		'width'				: 500,
		'height'			: 550,
		'autoScale'			: false,
		fitToView   		: false,
		autoSize    		: false,
		'transitionIn'		: 'none',
		'transitionOut'		: 'none',
		'type'				: 'iframe'
	});


    $('.editor').redactor();

	$(".slider-home").owlCarousel({
		navigation : false,
		smartSpeed: 450,
		paginationSpeed : 400,
		items : 1,
		loop : true,
		autoplay : true,
		stopOnHover : true,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		navigation: false,
		pagination: true,
		mouseDrag: false
	});

	$(".ultimiProdottiSlider").owlCarousel({
		navigation : false,
		smartSpeed: 450,
		paginationSpeed : 400,
		items : 1,
		loop : true,
		autoPlay : true,
		stopOnHover : true,
		navigation: true,
		pagination: false,
		mouseDrag: false
	});


	$('.datiDiversi a').click(function(){
		$('.nascosto').slideToggle();
	})
	
});


function ajaxPost(url,target,idform,params,callback){

	$.ajax({
	  url: url + "?" + params,
	  context: target,
	  type: 'POST',
	  data: $(idform).serialize(),
	  beforeSend: function() {
			$(target).fadeTo('fast', 0, function() {
				  // Animation complete.
				});
	  },
	  success: function(html){

		$(target).html(html);
				$(target).fadeTo('slow', 1.0, function() {
				  // Animation complete.					  
				});
			if (callback)
			{
				eval(callback);
			}
		}
	});
}


function ajaxGet(url,target,params,callback){

	$.ajax({
	  // url: url + "?" + params,
	  context: target,
	  type: 'GET',
	  beforeSend: function() {
			$(target).fadeTo('fast', 0, function() {
				  // Animation complete.
				});
	  },
	  success: function(html){

		$(target).html(html);
				$(target).fadeTo('slow', 1.0, function() {
				  // Animation complete.					  
				});
			if (callback)
			{
				eval(callback);
			}
		}
	});
}
